import http from "./config";

export const login = async (payload: {
  username: string;
  password: string;
}) => {
  return await http.post(`/pc/login`, payload);
};

export const makeFormSubmission = async (payload: any) => {
  return await http.post(`/api/v1/engine/create`, payload);
};
