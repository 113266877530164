interface IButton {
  btnText: string;
  onClick?: () => void;
  fullWidth?: boolean;
  disabled?: boolean;
  className?:string
  variant?: "outline" | "solid"
}
export const Button = ({
  btnText,
  onClick,
  fullWidth,
  disabled,
  className,
  variant,
  ...props
}: IButton) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={` ${
        disabled ? "bg-blue-200" : "bg-pc-yep-blue"
      } text-white rounded py-1 px-4 my-3 text-center ${
        fullWidth && "w-full"
      } ${className}  ${variant === "outline" && "border border-pc-blue !bg-white text-pc-blue"} `}
    >
      {btnText}
    </button>
  );
};
