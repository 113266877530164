import { useLayoutEffect, useState } from "react";
import "./App.css";
import "./styles/index.scss";
import AppRoutes from "./routes/Routes";
import { Toaster } from "react-hot-toast";

function App() {
  const NoWebScreen = () => {
    return (
      <div className="flex justify-center h-screen items-center">
        Currently not available on web, please use a mobile device
      </div>
    );
  };
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return (
    <>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      {width > 760 ? (
        <NoWebScreen />
      ) : (
        <div className="App">
          <AppRoutes />
        </div>
      )}
    </>
  );
}

export default App;
