import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
interface ModalProp {
  children: React.ReactNode;
  title?: React.ReactNode;
  titleIcon?: string;
  caption?: React.ReactNode;
  onClose: Function;
  footer?: React.ReactNode;
  show?: boolean;
  extraClassname?: string;
  preventClose?: boolean;
}
export const Modal = ({
  show,
  children,
  title = "",
  caption = "",
  titleIcon,
  onClose,
  footer,
  extraClassname,
  preventClose,
}: ModalProp) => {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={clsx(
            show ? " h-screen w-screen " : "h-0 w-0 opacity-0",
            "fixed top-0 left-0 z-50 bg-black bg-opacity-70 flex justify-center mb-20 overflow-y-auto"
          )}
        >
          <motion.div
            initial={{ y: "100vh" }}
            animate={{ y: 0 }}
            exit={{ y: "-100vh" }}
            className="absolute top-[20%] mx-auto  pb-20"
          >
            <div
              className={`bg-white rounded-lg min-w-[395px] ${extraClassname}`}
            >
              {preventClose !== true && (
                <button
                  className="absolute -top-8 right-0 h-5 w-5 rounded-full grid place-content-center bg-white"
                  onClick={() => onClose()}
                >
                  <XMarkIcon className="h-3 w-3" />
                </button>
              )}

              {title && (
                <div className="flex  px-6 items-center gap-3.5">
                  {titleIcon && (
                    <img className="h-8 w-8 object-contain" alt="" src={titleIcon} />
                  )}
                  <div
                    className={clsx(
                      caption ? "h-16" : "h-14",
                      " flex justify-center flex-col  "
                    )}
                  >
                    <h5 className="font-medium text-left text-lg text-zp-off-black">
                      {title}
                    </h5>
                    {caption && (
                      <div className="text-zp-label text-xs ">{caption}</div>
                    )}
                  </div>
                </div>
              )}

              <div className=" px-6 pt-3.5 pb-9">{children}</div>
              {footer && (
                <div className="flex justify-end px-6 py-4 border-t gap-3">
                  {footer}
                </div>
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
