import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { CustomerForm } from "../pages/form/data-form";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<CustomerForm />} />
        <Route path="/form" element={<CustomerForm />} />
        <Route path="*" element={<Navigate to="/form" />} />
       
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
