import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { TextInput } from "../../components/Forms/TextField";
import { Loader } from "../../components/Loader";
import { Button } from "../../components/Buttons/Button";
import { SelectField } from "../../components/Forms/SelectField";
import { Label } from "../../components/Forms/Label";
import { makeFormSubmission } from "../../utils/api.service";
import { FormResponseModal } from "../../containers/FormResponseModal";
import { states } from "../../utils/states";
import { CustomSelect } from "../../components/Forms/CustomSelect";

export const CustomerForm = () => {
  const [step, setStep] = useState<1 | 2>(1);
  const [responseModal, setResponseModal] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [modalState, setModalState] = useState<"error" | "success">("error");

  const stepOneValidation = yup.object({
    firstName: yup.string().required().label("first name"),
    lastName: yup.string().required().label("last name"),
    phoneNumber: yup.string().required().label("phone number"),
    // dateOfBirth: yup.string().required().label("date of birth"),
    // actualMaritalStatus: yup.string().required().label("marital status"),
    // educationalLevel: yup.string().required().label("educational level"),
    gender: yup.string().required().label("gender"),
    phoneTelco: yup.string().required().label("network"),
    // email: yup.string()
    //   .email()
    //   .matches(/^(?!.*@[^,]*,)/)
    //   .required(),
  });

  const stepTwoValidation = yup.object({
    stateOfResidence: yup.string().label("state of residence"),
    // lgaOfResidence: yup.string().required().label("lga of residence"),
    // stateOfOrigin: yup.string().required().label("state of residence"),
    // lgaOfOrigin: yup.string().required().label("lgaOfOrigin"), //location
    // country: yup.string().required().label("country"), //location
    addressName: yup.string().label("country"),
  });

  const form = useFormik({
    validationSchema: step === 1 ? stepOneValidation : stepTwoValidation,
    initialValues: {
      firstName: "", //section 1
      lastName: "", //section 1
      phoneNumber: "", //section 1
      // actualAgeRange: "", //section 1
      // actualEducation: "", //section 1
      actualMaritalStatus: "", //section 1
      phoneTelco: "", //section 1
      dateOfBirth: "", //section 1
      gender: "", //section 1
      educationalLevel: "", //section 1
      email:"",//sectyion 1

      // stateOfOrigin: "", // ========section 2
      // lgaOfOrigin: "", // ========section 2
      stateOfResidence: "", // ========section 2
      // lgaOfResidence: "", // ========section 2
      // country: "", // ========section 2
      addressName: "", // ========section 2

      // maritalStatus: "",
      // postalCode:"",
      // platform:""
    },

    onSubmit: async (values) => {
      step === 1 && setStep(2);

      if (step === 2)
        try {
          const res = await makeFormSubmission(values);
          if (res.data.statusCode !== "00") {
            setResponseModal(true);
            setModalState("error");
            setErrMessage(res.data.responseMessage || res.data.message);
          } else {
            setResponseModal(true);
            setModalState("success");
            form.resetForm();
            setStep(1);
          }
        } catch (error: any) {
          console.log("ERROR ==>", error.response);
        }
    },
  });

  const stepForm = {
    1: "Personal Details",
    2: "Location",
  };

  const maritatStatus = [
    "Single",
    "Married",
    "Widowed",
    "Divorced",
    "Seperated",
  ];
  const educationalLevels = [
    "No schooling completed",
    "Primary education",
    "High school diploma (SSCE)",
    "Bachelor's degree",
    "Post graduate degree",
  ];

  const TelcoOptions = ["MTN", "GLO", "AIRTEL", "ETISALAT"];

  return (
    <div
      className={`container mx-auto bg-[#F5F6F8] py-6 ${
        step === 2 && "h-[100vh]"
      }`}
    >
      <div className="py-4 flex justify-center">
        <img src="/images/yep-logo.svg" alt="yep-logo" />
      </div>
      <p className="text-gray-600 my-6 px-2">
        We are excited to have you here, please tell us more about yourself.
        This will take about 2 minutes of your time. Thank you.
      </p>

      <div className="flex text-pc-yep-blue my-1 text-sm font-semibold justify-end">
        STEP {step} of 2
      </div>
      <div className="rounded-lg bg-white p-4">
        <div className=" mb-6 py-6 border-b border-pc-light-grey">
          <h3 className="text-base font-semibold">{stepForm[step]}</h3>
        </div>

        <FormikProvider value={form}>
          <form onSubmit={form.handleSubmit} className="space-y-[28px]">
            {step === 1 && (
              <>
                <TextInput
                  label="First Name"
                  placeholder="Enter First Name"
                  {...form.getFieldProps("firstName")}
                  context
                />
                <TextInput
                  label="Last Name"
                  placeholder="Enter Last Name"
                  {...form.getFieldProps("lastName")}
                  context
                />
                <TextInput
                  label="Email Address"
                  optional
                  placeholder="Enter Email Address"
                  {...form.getFieldProps("email")}
                  type="email"
                  context
                />
                <TextInput
                  label="Phone Number"
                  placeholder="Enter Phone Number"
                  {...form.getFieldProps("phoneNumber")}
                  context
                />
                <div className="w-full">
                  <TextInput
                    label="Birthday"
                    type="date"
                    optional
                    placeholder="Enter First Name"
                    {...form.getFieldProps("dateOfBirth")}
                    context
                  />
                </div>

                <div className="mb-4">
                  <CustomSelect
                    name="gender"
                    context
                    label="Gender"
                    options={["Male", "Female"].map((option) => ({
                      value: option,
                      label: option,
                    }))}
                    value={form.values.gender}
                    placeholder="Select gender"
                    onChange={(val) => {
                      form.setFieldValue("gender", val.target.value);
                    }}
                  />
                </div>
                <div className="mb-4">
                  <CustomSelect
                    name="actualMaritalStatus"
                    context
                    optional
                    label="Marital Status"
                    options={maritatStatus.map((option) => ({
                      value: option,
                      label: option,
                    }))}
                    placeholder="Select option"
                    value={form.values.actualMaritalStatus}
                    onChange={(val) => {
                      form.setFieldValue(
                        "actualMaritalStatus",
                        val.target.value
                      );
                    }}
                  />
                </div>
                <div className="mb-4">
                  <CustomSelect
                    name="educationalLevel"
                    context
                    optional
                    label="Educational Level"
                    options={educationalLevels.map((option) => ({
                      value: option,
                      label: option,
                    }))}
                    value={form.values.educationalLevel}
                    placeholder="Select option"
                    onChange={(val) => {
                      form.setFieldValue("educationalLevel", val.target.value);
                    }}
                  />
                </div>

                <div className="mb-4">
                  <CustomSelect
                    name="phoneTelco"
                    context
                    label="Phone network"
                    options={TelcoOptions.map((option) => ({
                      value: option,
                      label: option,
                    }))}
                    value={form.values.phoneTelco}
                    placeholder="Select network"
                    onChange={(val) => {
                      form.setFieldValue("phoneTelco", val.target.value);
                    }}
                  />
                </div>
              </>
            )}

            {step === 2 && (
              <>
                <div className="mb-4">
                  <TextInput
                    label="Address"
                    placeholder="Enter address"
                    {...form.getFieldProps("addressName")}
                    context
                    optional
                  />
                </div>
                {/* <div className="mb-4">
                  <Label label="State of Origin" />
                  <SelectField
                    options={states.map((state) => ({
                      value: state.name,
                      label: state.name,
                    }))}
                    placeholder="Select state of Origin"
                    floating={false}
                    {...form.getFieldProps("stateOfOrigin")}
                    onChange={(val) => form.setFieldValue("stateOfOrigin", val)}
                  />
                </div> */}
                {/* <div className="mb-4">
                  <Label label="Local government of origin" />
                  <SelectField
                    options={states
                      .find((state) => state.name === form.values.stateOfOrigin)
                      ?.cities.map((city) => ({ value: city, label: city }))}
                    placeholder="Select local government of residence"
                    floating={false}
                    {...form.getFieldProps("lgaOfOrigin")}
                    onChange={(val) => form.setFieldValue("lgaOfOrigin", val)}
                  />
                </div> */}

                <div className="mb-4">
                  <Label label="State of Residence" />
                  <SelectField
                    options={states.map((state) => ({
                      value: state.name,
                      label: state.name,
                    }))}
                    placeholder="Select State of Residence"
                    floating={false}
                    {...form.getFieldProps("stateOfResidence")}
                    onChange={(val) =>
                      form.setFieldValue("stateOfResidence", val)
                    }
                  />
                </div>
                {/* <div className="mb-4">
                  <Label label="Local Government of Residence" />
                  <SelectField
                    options={states
                      .find(
                        (state) => state.name === form.values.stateOfResidence
                      )
                      ?.cities.map((city) => ({ value: city, label: city }))}
                    placeholder="Select Local Government of Residence"
                    floating={false}
                    {...form.getFieldProps("lgaOfResidence")}
                    onChange={(val) =>
                      form.setFieldValue("lgaOfResidence", val)
                    }
                  />
                </div> */}
                {/* <div className="mb-4">
                  <CustomSelect
                    name="country"
                    context
                    label="Country"
                    options={["Nigeria"].map((country) => ({
                      value: country,
                      label: country,
                    }))}
                    value={form.values.country}
                    placeholder="Select country"
                    onChange={(val) => {
                      form.setFieldValue("country", val.target.value);
                    }}
                  />
                </div> */}
              </>
            )}

            {form.isSubmitting ? (
              <div className="flex justify-center">
                <Loader />
              </div>
            ) : (
              <div
                className={`w-full flex ${
                  step === 1 ? "justify-end" : "justify-between gap-5"
                }`}
              >
                {step === 2 && (
                  <Button
                    btnText="Previous"
                    variant="outline"
                    onClick={() => setStep(1)}
                    fullWidth
                  />
                )}
                {
                  <Button
                    btnText={`${step === 1 ? "Next" : "Submit"}`}
                    fullWidth={step === 2}
                  />
                }
              </div>
            )}
          </form>
        </FormikProvider>
      </div>
      <FormResponseModal
        show={responseModal}
        errMessage={errMessage}
        modalState={modalState}
        onClose={() => {
          setResponseModal(false);
        }}
      />
    </div>
  );
};
