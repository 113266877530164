import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useRef, useState } from "react";
import { ErrorWrapper } from "./ErrorWrapper";
import { Label } from "./Label";

interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  floating?: boolean;
  required?: boolean;
  error?: string;
  type?: "text" | "number" | "password" | "email" | "tel" | "color" | "date";
  variant?: "outline" | "filled";
  context?: boolean;
  icon?: string;
  copyValue?: boolean;
  action?: React.ReactNode;
  optional?: boolean;
}

export const TextInput = ({
  variant = "outline",
  label = "",
  required = true,
  error = "",
  context = false,
  floating = false,
  icon,
  copyValue,
  action,
  optional,
  ...props
}: ITextInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [_focused, setFocused] = useState(false);
  const [_showText, setShowText] = useState(false);
  return (
    <ErrorWrapper show={context} name={props.name}>
      <div className="flex flex-col relative">
        <div className="flex justify-between items-center">
        <Label
          label={label}
          required={required}
          floating={floating}
          inputInFocus={_focused || Boolean(props.value)}
        />
        {optional && <span className="mb-[8px] text-gray-500">{'(Optional)'}</span>}
        </div>
        
        {props.type === "password" && (
          <button
            type="button"
            onClick={() => setShowText(!_showText)}
            className="absolute top-[41px] right-4"
          >
            {_showText ? (
              <EyeSlashIcon className="h-5 w-5" />
            ) : (
              <EyeIcon className="h-5 w-5" />
            )}
          </button>
        )}
        {icon && !floating && (
          <img
            className={clsx(
              "h-4 w-4 absolute left-3",
              label ? "top-[41px]" : " top-[18px]"
            )}
            alt=""
            src={icon}
          />
        )}
        <input
          ref={inputRef}
          className={clsx(
            variant === "filled" && "bg-[#F7F7FC]",
            variant === "outline" &&
              "border  ",
            floating ? "h-14 pt-2" : "h-[46px]",
            " rounded-[4px] px-4 text-sm",
            "",
            copyValue && "!pr-[84px]",
            icon && !floating && "!pl-9",
            props.type === "color" && "w-full",
            (props.readOnly || props.disabled) && "bg-gray-100"
          )}
          {...props}
          type={props.type === "password" && _showText ? "text" : props.type}
          onFocus={(e) => {
            setFocused(true);
            props.onFocus?.(e);
          }}
          onBlur={(e) => {
            setFocused(false);
            props.onBlur?.(e);
          }}
          onAnimationStart={(e) => {
            if (e.animationName === "onAutoFillStart") {
              setFocused(true);
            }
          }}
        />

        {action && (
          <div className="flex cursor-pointer absolute top-1/2 -translate-y-1/2 right-0">
            {action}
          </div>
        )}
      </div>
    </ErrorWrapper>
  );
};
