import clsx from "clsx";
import React from "react";
import { ErrorWrapper } from "./ErrorWrapper";
import { Label } from "./Label";

interface ISelectProps {
  label?: string;
  floating?: boolean;
  required?: boolean;
  value?: string;
  error?: string;
  type?: "text" | "number" | "password" | "email" | "tel" | "color" | "date";
  variant?: "outline" | "filled";
  context?: boolean;
  icon?: string;
  copyValue?: boolean;
  optional?: boolean;
  name: string;
  action?: React.ReactNode;
  options?: Array<
    | {
      label: string | React.ReactNode;
      value: string | number;
      isDisabled?: boolean;
    }
    | string
  >;
  placeholder?: string;
  onChange?: (value: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const CustomSelect = ({
  variant = "outline",
  label = "",
  required = true,
  error = "",
  context = false,
  floating = false,
  icon,
  copyValue,
  optional,
  action,
  name,
  options,
  placeholder,
  value,

  onChange,
  ...props
}: ISelectProps) => {
  const selectOptions = options?.map((opt) => ({
    label: typeof opt === "string" ? opt : opt.label,
    value: typeof opt === "string" ? opt : opt.value,
    isDisabled: typeof opt === "string" ? undefined : opt.isDisabled,
  }));

  return (
    <ErrorWrapper show={context} name={name}>
      <div className="flex flex-col relative">
        <div className="flex justify-between items-center">
          <Label
            label={label}
            required={required}
            floating={floating}
          />
          {optional && <span className="mb-[8px] text-gray-500">{'(Optional)'}</span>}
        </div>

        <select
          name={name}
          value={value}
          onChange={onChange}
          className={clsx(
            variant === "filled" && "bg-[#F7F7FC]",
            variant === "outline" && "border  ",
            floating ? "h-14 pt-2" : "h-[46px]",
            " rounded-[4px] px-4 text-sm",
            "",
            copyValue && "!pr-[84px]",
            icon && !floating && "!pl-9",
            props.type === "color" && "w-full"
          )}
        >
          <option value="" disabled selected hidden>
            {placeholder}
          </option>
          {selectOptions?.map((option, i) => (
            <option value={option.value} className="text-grey-300">
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </ErrorWrapper>
  );
};
