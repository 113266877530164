import { Modal } from "../components/Modal/Modal";
import { Button } from "../components/Buttons/Button";

interface FormResponseProp {
  onClose: () => void;
  show: boolean;
  errMessage?: string;
  modalState:"error"|"success"
}
export const FormResponseModal = ({
  onClose,
  show,
  errMessage,
  modalState
}: FormResponseProp) => {

  return (
    <Modal onClose={onClose} show={show} title="">
      {modalState === "error" && (
        <div className="px-4  text-center">
          <div className="my-4">
            <div className="font-semibold text-[#C31B1B] text-xl ">Error</div>
            <p className="font-normal text-sm leading-1">{errMessage}</p>
          </div>

          <img
            src="/icons/error.svg"
            className="mx-auto w-[70px] mb-4"
            alt=""
          />

          <Button
            btnText="Retry"
            onClick={() => {
              onClose();
            }}
          />
        </div>
      )}
      {modalState === "success" && (
        <div className="px-4  text-center">
          <div className="my-4">
            <div className="font-bold text-green-500 mb-3 text-xl">
              {" "}
              Successful
            </div>
            <p className="text-pc-light-blue font-semibold leading-5 text-normal">
            Thank you for signing up as a Yep! Customer. You’re one transaction away from getting your loyalty points.
            </p>
          </div>

          <img
            src="/icons/green-check.svg"
            className="mx-auto w-2/5  mb-4"
            alt=""
          />

          <Button
            btnText="DONE"
            className="bg-green-500 shadow"
            onClick={() => {
              onClose();
            }}
          />
        </div>
      )}
    </Modal>
  );
};
